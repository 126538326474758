<script lang="ts">
/* eslint-disable style/no-multi-spaces */
export const sizes = {
  '2xs': `text-xs`,   // 10px
  'xs': ` text-sm`,   // 12px
  'sm': ` text-md`,   // 14px
  'md': ` text-xl`,   // 20px
  'lg': ` text-2xl`,  // 24px
  'xl': ` text-3xl`,  // 28px
  '2xl': `text-4xl`,  // 36px
  '3xl': `text-5xl`,  // 48px
} as const
</script>

<script setup lang="ts">
/* eslint-disable import/first */
import type { Value, ValueFormat } from '#core/utils/format-value'
import { formatValue } from '#core/utils/format-value'
import { getSlotTextContent } from '#core/utils/vue'

/**
 * UiFormat
 *
 * Display component designed to format values
 *
 * @see
 */
const props = withDefaults(defineProps<{
  value?: Value
  format?: ValueFormat
  suffix?: string
  size?: keyof typeof sizes
}>(), {
  // `undefined` is required to pass to formatValue()
  value: undefined,
  size: undefined,
})

const base = 'font-display overflow-hidden font-semibold'

const slots = useSlots()

const formatted = computed(() => {
  const value = slots.default
    // grab text content
    ? getSlotTextContent(slots.default)

    // grab value
    : props.value

  // format value
  let output = formatValue(value, props.format)

  // replace UTF8 ordinals with HTML
  const ordinals = /[₀₁₂₃₄₅₆₇₈₉]/g
  if (ordinals.test(output)) {
    output = output.replace(ordinals, c => `<sub>${ordinals.source.substring(1).indexOf(c)}</sub>`)
  }

  // return
  return output
})

const classes = computed(() => {
  const isNumeric = /^(?:percent|number|currency)/.test(props.format)
  return [
    sizes[props.size as keyof typeof sizes],
    props.size ? base : '',
    isNumeric
      ? 'tracking-[-0.02em]'
      : '',
  ]
})
</script>

<template>
  <figure data-ui="UiFormat" :data-format="format" :class="classes">
    <span v-html="formatted" />
    <slot name="suffix">
      {{ suffix }}
    </slot>
  </figure>
</template>
